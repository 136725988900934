/* user-detail */
.user-detail-content .user-box {
    padding: 24px;
    background: #fff;
    position: relative;
    border-right: 10px solid lightgrey;
}

.user-detail-content .user-box .info .nickname {
    margin: 8px 0;
}

.user-detail-content .user-box .info .nickname span {
    font-size: 14px;
    padding: 0 6px;
}

.user-detail-content .user-box .info {
    line-height: 24px;
    margin-left: 94px;
}

.user-detail-content .user-box .info .other span {
    float: left;
    display: inline;
    height: 14px;
    line-height: 14px;
    border-right: 1px solid #ccc;
    padding: 0 10px;
    margin: 5px 0;
    color: #666;
}

.user-detail-content .user-box .info .other span:last-child {
    border-right: none;
}

.user-detail-content .user-box .info .other span i {
    color: #7e2bdb;
    margin-left: 4px;
}

.user-detail-content .wallet-box {
    padding: 24px;
    background: #fff;
    position: relative;
    border-left: 10px solid lightgrey;
}

.user-detail-content .wallet-box li img {
    float: left;
    display: inline;
}

.user-detail-content .wallet-box li p {
    margin-left: 60px;
}

.user-detail-content .wallet-box li span {
    line-height: 32px;
    display: block;
    font-size: 22px;
    font-weight: 700;
}

.user-detail-content .wallet-box li i {
    display: block;
}

@media only screen and (max-width: 600px) {
    .user-detail-content .user-box {
        border-right: 0px solid lightgrey;
    }

    .user-detail-content .wallet-box {
        border-left: 0px solid lightgrey;
    }
}

/* user-notice-statistic */
.user-statistic-content {
    padding: 24px;
    background: #fff;
    margin: 10px 0;
    margin-top: 0;
}

.user-statistic-content .hd {
    padding: 24px 0;
    padding-top: 0;
}

.statistic-box p {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-radius: 10px;
    background: #f3f4f6;
    margin-right: 24px;
}

.statistic-box p span {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
}

/* user-media-cards */
.user-media-content {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    padding-right: 0;
    padding-bottom: 0;
}

.user-media-box-title .hd {
    padding: 24px 0;
    padding-top: 0;
}

/* .user-media-card-box .card-plat .active:after {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    border: 14px solid #fff;
} */

.user-media-card-box .card-plat p {
    width: 310px;
    height: 80px;
    text-align: center;
    box-shadow: 0px 0px 20px 0px #ccc;
    position: relative;
    margin: 0 35px 36px 0;
    border: 1px solid #fff;
    justify-content: space-evenly;
    border: 1px solid #ff2600f6;
}

.user-media-card-box .card-plat p img {
    /* margin-left: 80px; */
}

.user-media-card-box .card-plat p span {
    margin-left: 16px;
    font-size: 16px;
    width: 80%;
}
.new-media-card-collapse-btnbox {
    display: flex;
    justify-content: center;
}

.new-media-card-collapse-button:hover {
    background-color: rgb(184, 224, 219);
    color: rgb(82, 82, 74);
    box-shadow: rgba(32, 31, 31, 0.35) 0px 7px 10px;
    font-weight: 900;
}

.new-media-card-collapse-button {
    height: 120px;
    margin: 0 24px 0 0;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 24px;
    cursor: default;
}

.new-media-card-collapse-button i {
    font-size: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    margin-bottom: 12px;
    background: #ff2600f6;
    text-align: center;
    color: #fff;
}