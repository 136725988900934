@media only screen and (max-width: 600px) {
    .lang-btn {
        height: 55px !important;
        width: 150px !important;
    }
}

nav {
	overflow: visible;
}

nav li a {
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

nav ul a,
nav .brand-logo {
  left: 10%;
  color: white;
}

nav a.button-collapse {
    color:white;
    margin-left: 10px;
}

.nav-wrapper {
    padding: 0;
}

.subnav-dropdown-content {
    display: block;
    white-space: nowrap;
    height: auto;
    width: fit-content;
    position: fixed;
    z-index: 1;
    top: 64px;
    right:35%;
    background-color: #616161 !important;
    overflow-x: hidden;
}

.subnav-dropdown-content > li {
    display:block;
    float:left;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
}

.lang-btn {
    position: absolute;
    height: 64px;
    width: 180px;
    top: 0;
    right: 0;
    text-align: center;
    padding: 0px;
}

.lang-btn p {
    margin: 0;
    width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;;
}

.side-nav {
    opacity: 0.9 !important;
    left: 0 !important;
    top: 56px !important;
}

#side-nav-lang {
    width: 100%;
    background-color: cadetblue;
    position: absolute !important;
    bottom: 124px !important;
    text-align: center;
}

.menu-back {
    width: 100vw;
    height: 100vh;
}

@media only screen and (max-width: 992px) {
    nav .brand-logo {
        left: 30%;
    }

    #index-banner .section .container {
        margin-top: 20vh;;
    }
}