footer {
    background-color: #616161 !important;
    padding-top: 50px !important;
    padding-left: 10%;
    padding-right: 10%;
}

footer a {
    color: darkgrey;
    font-size: medium;
}
footer a:hover {
    color: #CC0066;
}

footer p {
    color: darkgrey;
    font-size: medium;
}