#walletboard {
    padding-left: 0;
    padding-right: 30px;
}

@media only screen and (max-width: 600px) {
    #walletboard {
        padding-left: 0;
        padding-right: 0;
    }
}

/* wallet-card */
.wallet-card {
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
    height: 180px;
}

.wallet-card-title {
    padding: 20px;
    font-weight: bold;
    font-size: 16px;
}

.wallet-income-wrap {
    padding: 0 70px 20px;
}

.wallet-income-item {
    width: 33.3%;
    text-align: center;
    position: relative;
}

/* wallet-recharge */
.wallet-tab-inner {
    display: inline-block;
    height: 48px;
    background-color: #EFF3F7;
}

.wallet-tab-item.active {
    color: #FF405C;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
}

.wallet-tab-item {
    width: 168px;
    color: #666666;
    background-color: transparent;
    line-height: 44px;
    text-align: center;
}

.wallet-card-form {
    min-height: 300px;
    border-radius: 5px;
    background: #fff;
    padding-top: 12px;
    position: relative;
}

.addContForm {
    padding: 0 20px;
}

.addContForm dl {
    padding: 0;
    padding: 8px 0;
}

.addContForm dl dt {
    width: auto;
    height: auto;
    line-height: 20px;
    font-size: 14px;
    color: #666666;
}

.addContForm dl dt {
    float: left;
    width: 140px;
    color: #000;
    height: 40px;
    line-height: 40px;
}

.addContForm dl dd {
    line-height: 20px;
    font-size: 14px;
    color: #333333;
}

.addContForm dl dd {
    float: left;
    display: inline;
}

.wallet-money-num {
    font-size: 18px;
    color: #FF405C;
    line-height: 18px;
    font-family: "OPPOSansB";
}

.rechargeBox a.se {
    border-color: #FF405C;
    background: #FEECEF;
}

.rechargeBox a {
    display: inline-block;
    width: 142px;
    height: 44px;
    line-height: 42px;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    margin-right: 15px;
}

.wallet-custome-money-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.rechargeBox a.se .wallet-custome-inp {
    background: #FEECEF;
}
.wallet-custome-money-wrap .wallet-custome-inp {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0 36px 0 20px;
    vertical-align: 0px;
    font-size: 20px;
    color: #FF405C;
}

.wallet-money-sign {
    font-size: 12px;
    color: #FF405C;
    line-height: 20px;
    font-family: "OPPOSansB";
}

.wallet-custome-money-wrap .wallet-money-sign {
    position: absolute;
    left: 10px;
    top: 14px;
    color: #FF405C;
}
.wallet-money-sign {
    font-size: 12px;
    color: #FF405C;
    line-height: 20px;
    font-family: "OPPOSansB";
}

.wallet-recharge-btn-wrap {
    text-align: center;
    margin-top: 50px;
}

.wallet-recharge-btn {
    display: inline-block;
    width: 160px;
    height: 34px;
    line-height: 34px;
    color: #fff;
    background: #FF405C;
    font-size: 14px;
    border-radius: 17px;
}

.wallet-recharge-notice {
    font-size: 12px;
    color: #AAAAAA;
    line-height: 12px;
    margin-top: 10px;
    text-align: center;
}