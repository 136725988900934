/* Sidebar */
.sidebar-sticky { position: -webkit-sticky; position: sticky; top: 48px; height: calc(100vh - 48px); padding-top: .5rem; overflow-x: hidden; overflow-y: auto; }

.sidebar .nav-link {
    font-weight: 300;
    color: #333;
    position: relative;
    display: block;
    padding: .5em 1em;
    text-decoration: none;
}

.sidebar .nav-link:hover {
    color: #ff2600f6;
}

.sidebar .nav-link.active {
    color: #ff2600f6;
    border-left: 3px solid #ff2600f6;
}

.nav-link-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.sidebar-heading { padding: 1rem; margin-top: 1rem; background-color: #eee; color: #7d7a7a; }

.nav .nav { padding-left: 1em; background-color: #eee; display: none; }

.nav .caret { -webkit-transition: transform 0.3s; -webkit-transition: -webkit-transform 0.3s; transition: -webkit-transform 0.3s; transition: transform 0.3s; transition: transform 0.3s, -webkit-transform 0.3s; }

.nav .active .caret { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
