.flex-ai-c {
    align-items: center;
}

.flex-fd-c {
    flex-direction: column;
}

.flex-jc-c {
    justify-content: center;
}

.flex-jc-sb {
    justify-content: space-between;
}

.new-media-form {
    width: 1000px;
    margin: 30px auto;
}

.new-media-form-header {
    height: 20px;
    line-height: 20px;
    margin-bottom: 24px;
}

.new-media-form-header .title {
    font-size: 18px;
    padding-left: 10px;
    border-left: 4px solid #ff2600f6;
}

.new-media-form .new-media-form-body .el-form-item {
    margin-bottom: 22px;
    display: block;
}

.new-media-form .new-media-form-body .el-form-item .el-form-item__label {
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
    float: none;
    padding: 0;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
}

.new-media-form .new-media-form-body .el-form-item .el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
    height: auto;
}

.new-media-form .new-media-form-body .el-form-item .el-form-item__content:before {
    display: table;
    content: "";
}

.new-media-form .new-media-form-body .el-form-item .el-form-item__content .el-upload--picture-card {
    text-align: center;
    cursor: pointer;
    outline: 0;
    background-color: #fbfdff;
    border-radius: 6px;
    box-sizing: border-box;
    vertical-align: top;
    width: 112px;
    height: 112px;
    margin: 0 8px 8px 0;
    line-height: 0;
    background: #f5f5f5;
    border: none;
    display: inline-block;
}

.new-media-form .new-media-form-body .el-form-item .el-form-item__content .el-upload--picture-card .upload-area {
    height: 100%;
}

.new-media-form .new-media-form-body .el-form-item .el-form-item__content .el-upload--picture-card .upload-area i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: 700;
    color: #fff;
    font-size: 18px;
    padding-top: 0;
    border-radius: 50%;
    background: #ff2600f6;
}

.new-media-form .new-media-form-body .el-form-item .el-form-item__content .el-upload--picture-card .el-upload__input {
    display: none;
}

.new-media-form .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 95%;
}

.new-media-form .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
    line-height: 16px;
    padding: 12px;
    font-size: 14px;
    height: 36px;
    padding: 10px;
}

.new-media-form .model {
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-top: 2px;
}

.new-media-form-body .model {
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-top: 2px;
    width: 95%;
}

.new-media-form-body .model span {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-right: 1px solid #fff;
    border-radius: 4px;
    text-align: center;
}

.new-media-form-body .model .active {
    border: 1px solid #ff2600f6;
}

.new-media-form .address-select .el-cascader {
    display: inline-block;
    position: relative;
    font-size: 14px;
    line-height: 40px;
    width: 100%;
}

.new-media-form .address-select .el-cascader .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.new-media-form .address-select .el-cascader .el-input .input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    outline: 0;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
    line-height: 16px;
    font-size: 14px;
    text-overflow: ellipsis;
    height: 36px;
    padding: 10px;
}

.new-media-form .address-select .el-cascader .el-input .el-input__suffix {
    pointer-events: none;
    position: absolute;
    top: 0;
    height: 100%;
    color: #c0c4cc;
    text-align: center;
    right: 5px;
    transition: all .3s;
}

.new-media-form .address-select .el-cascader .el-input .el-input__suffix .el-input__suffix-inner {
    pointer-events: all;
}

.new-media-form .address-select .el-cascader .el-input .el-input__suffix .el-input__suffix-inner i {
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    width: 25px;
    text-align: center;
    line-height: 40px;
    transition: transform .3s;
    font-size: 14px;
}

.address-select .form-select {
    display: block;
    background-color: white;
}

/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 10px;
    left: 5px;
    height: 20px;
    width: 20px;
    background-color: #eee;
}