#noticeboard {
    padding-top: 20px;
    ;
    padding-left: 0;
    padding-right: 30px;
}

@media only screen and (max-width: 600px) {
    #noticeboard {
        padding-left: 0;
        padding-right: 0;
    }
}

.new-notice-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    border-radius: 50%;
}

/* add-notice */
.notice-newNotice-box {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 24px;
}

.notice-newNotice-1 {
    clear: both;
    zoom: 1;
    margin-bottom: 24px;
    display: flex;
}

.notice-newNotice-platform .active {
    color: #ff2600f6;
    border-color: #ff2600f6;
}

.notice-newNotice-platform li {
    height: 40px;
    margin-right: 34px;
    padding-bottom: 8px;
    border-bottom: 2px solid #fff;
}

.notice-newNotice-platform img {
    vertical-align: middle;
}

.notice-newNotice-platform span {
    line-height: 30px;
    padding-left: 8px;
}

.notice-newNotice-2 {
    display: block;
    flex-wrap: nowrap;
    line-height: 24px;
    margin-bottom: 14px;
    overflow: hidden;
    color: #999;
}

.notice-newNotice-2-item {
    float: left;
    padding: 0 8px;
    margin: 0 24px 10px 0;
    cursor: pointer;
}

.notice-newNotice-2-item-active {
    background: #f2e6ff;
    color: #ff2600f6;
    border-radius: 2px;
    cursor: pointer;
}

.notice-newNotice-3 {
    width: 30%;
    display: inline-grid;
    margin-left: 0%;
    margin-right: 3%;
    margin-bottom: 24px;
}

.notice-newNotice-4 {
    display: flex;
    flex-wrap: nowrap;
    line-height: 24px;
    margin-bottom: 14px;
    overflow: hidden;
    color: #999;
}

.notice-newNotice-5 {
    /* width: 30%;
    margin-left: 0%;
    margin-right: 3%; */
    margin-bottom: 24px;
}

.notice-expect-group {
    flex-wrap: wrap;
    display: flex;
}

/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.newNotice-input-box {
    display: block;
    width: 100%;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219/var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity));
    padding: 0.625rem;
    font-size: .875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(17 24 39/var(--tw-text-opacity));
}

.newNotice-collapse-btnbox {
    display: flex;
    justify-content: center;
}

.newNotice-collapse-button:hover {
    background-color: rgb(184, 224, 219);
    color: rgb(82, 82, 74);
    box-shadow: rgba(32, 31, 31, 0.35) 0px 7px 10px;
    font-weight: 900;

}

.newNotice-collapse-button {
    background-color: lightgray;
    height: 22px;
    font-size: 20px;
    margin: 5px;
    border: none;
    width: 34px;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .notice-newNotice-1 {
        display: grid;
    }

    .notice-newNotice-platform .active {
        color: white;
        background-color: #ff2600f6;
    }

    .notice-newNotice-platform li {
        margin-bottom: 10px;
        height: 30px;
        padding-right: 8px;
    }
}

/* notice-filter */
.notice-filter-box {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 24px;
}

.notice-filter-1 {
    clear: both;
    zoom: 1;
    margin-bottom: 24px;
    display: inline-block;
}

.notice-filter-platform .active {
    color: #ff2600f6;
    border-color: #ff2600f6;
}

.notice-filter-platform li {
    height: 40px;
    margin-right: 34px;
    padding-bottom: 8px;
    border-bottom: 2px solid #fff;
}

.notice-filter-platform img {
    vertical-align: middle;
}

.notice-filter-platform span {
    line-height: 30px;
    padding-left: 8px;
}

.notice-filter-2 {
    display: flex;
    flex-wrap: nowrap;
    line-height: 24px;
    margin-bottom: 14px;
    overflow: hidden;
    color: #999;
}

.notice-filter-2-item {
    float: left;
    padding: 0 8px;
    margin: 0 24px 10px 0;
    cursor: pointer;
}

.notice-filter-2-item-active {
    background: #f2e6ff;
    color: #ff2600f6;
    border-radius: 2px;
    cursor: pointer;
}

.notice-filter-3 {
    width: 40%;
}

.notice-expect-group {
    flex-wrap: wrap;
    display: flex;
}

/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filter-input-box {
    display: block;
    width: 100%;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219/var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity));
    padding: 0.625rem;
    font-size: .875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(17 24 39/var(--tw-text-opacity));
}

.filter-collapse-btnbox {
    display: flex;
    justify-content: center;
}

.filter-collapse-button:hover {
    background-color: rgb(184, 224, 219);
    color: rgb(82, 82, 74);
    box-shadow: rgba(32, 31, 31, 0.35) 0px 7px 10px;
    font-weight: 900;
}

.filter-collapse-button {
    background-color: lightgray;
    height: 22px;
    font-size: 20px;
    margin: 5px;
    border: none;
    width: 34px;
    border-radius: 5px;
}

/* notice-list */
.notice-empty-list {
    text-align: center;
    margin: 24px 0;
    color: #999;
}

.notice-list-box {
    overflow: hidden;
}

.notice-list-ul {
    flex-wrap: wrap;
    margin-right: -1.66%;
}

.notice-li {
    background: #fff;
    border: 1px solid #f6f6f8;
    position: relative;
    width: 15%;
    margin: 0 1.66% 24px 0;
}

.notice-list-item {
    padding: 0;
    position: relative;
}

.notice-list-item .pic {
    background: #ccc;
}

.notice-list-item .pic img {
    border-radius: 4px 4px 0 0;
    width: 100%;
    height: 100%;
    max-height: 282px;
}

.notice-list-intro {
    padding: 0 10px;
}

.notice-list-intro-info {
    margin-top: 12px;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
}

.notice-list-intro-title {
    margin-right: 4px;
    font-size: 16px;
    color: #ff2600f6;
}

.notice-list-intro-platform {
    float: right;
}

.notice-list-intro-platform img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
}

.notice-list-desc {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 40px;
    line-height: 20px;
    margin: 8px 0;
    overflow: hidden;
}

.notice-list-other {
    height: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    overflow: hidden;
    color: #999;
}

.notice-list-other span {
    float: left;
    display: inline;
}

.notice-list-other-line {
    padding: 0 5px;
}

@media only screen and (max-width: 600px) {
    .notice-li {
        width: 100%;
    }
}

/* notice-pagination */
.notice-pagination-box {
    display: block;
    unicode-bidi: isolate;
    text-align: center;
    margin-bottom: 0;
}

.notice-pagination-elem {
    vertical-align: baseline;
    white-space: nowrap;
    padding: 2px 5px;
    color: #303133;
    font-weight: 700;
}

.notice-pagination-elem button {
    overflow: visible;
    text-transform: none;
    display: inline-block;
    font-size: 13px;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    box-sizing: border-box;
    border: none;
    background: 50% no-repeat #fff;
    background-size: 16px;
    padding: 0;
    margin: 0 5px;
    background-color: #f4f4f5;
    min-width: 30px;
    border-radius: 2px;
    color: #c0c4cc;
}

.notice-pagination-elem-pager {
    vertical-align: top;
    margin: 0;
    display: inline-block;
    -ms-user-select: none;
    user-select: none;
    list-style: none;
}

.notice-pagination-elem-pager li:not(.disabled).active {
    background-color: #ff2600f6;
    color: #fff !important;
}

.notice-pagination-elem-pager li {
    vertical-align: top;
    display: inline-block;
    padding: 0 4px;
    font-size: 13px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    text-align: center;
    margin: 0 5px;
    min-width: 30px;
    border-radius: 2px;
    font-weight: 400;
    cursor: default;
}

.notice-pagination-elem-jump {
    margin-left: 24px;
    font-weight: 400;
    color: #606266;
    display: inline-block;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    box-sizing: border-box;
}

.notice-pagination-elem-editor {
    position: relative;
    display: inline-block;
    font-size: 14px;
    width: 50px;
}

.notice-pagination-elem-input {
    text-align: center;
    padding: 0 3px;
    height: 28px !important;
    line-height: 28px !important;
}

.notice-pagination-elem-total {
    margin-right: 10px;
    font-weight: 400;
    color: #606266;
    display: inline-block;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: 30px;
}

@media only screen and (max-width: 600px) {
    .notice-pagination-elem {
        display: contents;
    }

    .notice-pagination-elem-total {
        width: 100%;
    }
}