/* notice-info */
.notice-info-body {
    direction: ltr;
}

.notice-info-body .box {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
}

.notice-info-cover {
    width: 30%;
    margin-right: 24px;
}

.notice-info-cover img {
    width: 100%;
    height: auto;
}

.notice-info-intro {
    position: relative;
}

.flex-ai-c {
    align-items: center;
}

.notice-info-intro .row1 p {
    padding: 5px 20px 20px 0;
}

.notice-info-title {
    line-height: 30px;
    padding-right: 10px;
}

.notice-info-intro .row3 {
    margin-bottom: 20px;
}

.notice-type {
    font-size: 20px;
    color: #7e2bdb;
}

.notice-time-release {
    height: 24px;
    line-height: 24px;
    color: #999;
}

.notice-info-intro .row4 {
    height: 18px;
    line-height: 18px;
    margin-bottom: 20px;
    overflow: hidden;
    justify-content: left;
}

.notice-info-intro .row4 span {
    float: left;
    width: 32%;
    white-space: nowrap;
}

.notice-info-intro .row4 i {
    padding-left: 20px;
}

.notice-info-intro .row4 img {
    vertical-align: middle;
    margin-right: 4px;
}

.notice-info-intro .row5 {
    line-height: 26px;
}

.notice-info-intro .row5 span {
    padding-right: 4px;
    margin-bottom: 30px;
}

.notice-info-intro .row5 i {
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    margin: 0 0 16px 16px;
    background: #f5f5f5;
    white-space: nowrap;
}

.notice-info-intro .row6 {
    margin-top: 6px;
}

.notice-info-botton {
    display: inline-block;
    width: 110px;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background: #7e2bdb;
}

.notice-report-botton {
    display: block;
    height: 20px;
    line-height: 20px;
    background-size: 20px auto;
    padding-left: 26px;
    color: #999;
}

.notice-publisher-header {
    padding: 0 0 24px 0;
}

.notice-publisher-header .title {
    font-size: 16px;
}

.notice-publisher-header .more {
    color: #999;
}

.notice-publisher-body .profile-photo {
    width: 60px;
    margin-right: 16px;
}

.notice-publisher-body .contact {
    margin-bottom: 16px;
    align-self: flex-start;
}

.notice-publisher-body .contact span {
    margin-right: 50px;
}

.notice-publisher-body .contact span {
    margin-right: 50px;
}

.notice-publisher-body .evaluation {
    margin-bottom: 16px;
    align-self: flex-start;
}

.notice-info-body .detail {
    line-height: 24px;
}

.notice-detail-header {
    padding: 0 0 24px 0;
    padding-bottom: 20px;
}

.notice-detail-header .title {
    font-size: 16px;
}

.notice-detail-body pre {
    white-space: pre-wrap;
}

.notice-picture-header {
    padding: 0 0 24px 0;
}

.notice-picture-header span {
    display: block;
}

.notice-picture-header span:last-child {
    margin-bottom: 0;
}

.notice-picture-body span {
    display: block;
}

.notice-picture-body .img {
    max-width: 60%;
    min-width: 150px;
}

/* related-notice-list */
.relate-notice-box {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
}

.relate-notice-box .hd {
    padding: 0 0 24px 0;
}

.relate-notice-box li {
    margin-bottom: 24px;
}

.relate-notice-box li .pic {
    width: 90px;
    height: 90px;
}

.relate-notice-box li .info {
    margin-left: 100px;
}

.relate-notice-box li .info .title1 {
    padding-right: 64px;
}

.relate-notice-box li .info .title {
    height: 28px;
    line-height: 28px;
    margin-bottom: 8px;
    position: relative;
}

.relate-notice-box li .info span {
    display: block;
}

.relate-notice-box li .info .plat {
    margin-bottom: 8px;
}

.relate-notice-box li .info .plat img {
    margin-right: 4px;
}

.relate-notice-box li .info .fans .i1 {
    color: #999;
}

.relate-notice-box li .info .fans .i2 {
    color: #7e2bdb;
}