#serviceboard {
    padding-left: 0;
    padding-right: 30px;
}

@media only screen and (max-width: 600px) {
    #serviceboard {
        padding-left: 0;
        padding-right: 0;
    }
}

/* good-red-recommend */
.recommend {
    width: 997px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px 20px 30px 20px;
}

.module-name {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 28px;
}

.module-name .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.recommend-title {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
}

.promotion-subtitle {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.more a {
    float: right;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
}

.card-main {
    /* overflow: hidden; */
    min-height: 444px;
    margin: 20px -20px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.recommend-account-item.douyin {
    background-color: blue;
    /* background: url(/images/card_bg_blue.png) no-repeat top left; */
    -o-background-size: 306px 100%;
    background-size: 306px 100%;
}

.recommend-account-item {
    width: 306px;
    min-height: 444px;
    background-color: yellow;
    /* background: url(/images/card_bg_yellow.png) no-repeat top left; */
    -o-background-size: 306px 100%;
    background-size: 306px 100%;
    border-radius: 10px;
    opacity: 1;
    margin-left: 20px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
    top: 0;
}

.capsule-wrap {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 2px 0;
    width: 30px;
    height: 51px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    opacity: 1;
    position: absolute;
    top: 25px;
    right: 20px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 10;
}

.recommend-account-item .a-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.recommend-account-item .echarts-box {
    width: 100%;
    height: 204px;
    margin-top: 10px;
}

.recommend-account-item .desc-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #999999;
    font-size: 12px;
}

.recommend-account-item .count-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pagination-wrap {
    display: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 10px;
}

/* main-content-detail */
.detail-cont {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.detail-head {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.head-username {
    font-size: #333333;
    font-size: 20px;
    font-weight: bold;
    font-family: PingFang SC-Medium, PingFang SC;
    line-height: 28px;
}

.auth-status-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 10px;
    padding-right: 10px;
}

.sub-head {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
}

.head-help {
    font-size: 12px;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.money-area {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
}

.head-money {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.head-money .balance, .head-money .freeze {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 18px;
    text-align: center;
    color: #333333;
    font-weight: normal;
    font-family: OPPOSansB, OPPOSans;
    line-height: 24px;
}

.func-area {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pay-btn {
    width: 133px;
    height: 40px;
    text-align: center;
    background: -webkit-linear-gradient(315deg, #FF405C 0%, #FFA78A 100%);
    background: -moz- oldlinear-gradient(315deg, #FF405C 0%, #FFA78A 100%);
    background: -o-linear-gradient(315deg, #FF405C 0%, #FFA78A 100%);
    background: linear-gradient(135deg, #FF405C 0%, #FFA78A 100%);
    opacity: 1;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

/* red-promotion */
.promotion {
    width: 100vw;
    max-width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px 20px 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.module-name {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 28px;
}

.module-name .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.promotion-title {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
}

.promotion-subtitle {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.promote-type {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px;
}

.promote-type li {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 140px;
    cursor: pointer;
    position: relative;
    overflow: visible;
}

.promote-type p {
    margin-top: 15px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.promote-type .plat-icon {
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

.promote-type .plat-bg {
    position: absolute;
    width: 160px;
    height: 140px;
    bottom: 0;
    left: 50%;
    margin-left: -80px;
}

.promote-type li > a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 10;
}