@media only screen and (max-width: 992px) {
    .post-preview .post-title {
        text-wrap: pretty;
    }
    .card-content .post-title {
        text-wrap: pretty;
    }
    .card-content .post-title {
        text-wrap: pretty;
    }
    .summary_data_table {
        display: none;
        visibility: hidden;
    }
}

#index-banner .banner-title {
    font-size: calc(4rem + 5vw);
    margin: 5%;
    line-height: 100px;
  }
  
  #index-banner .banner-subtitle {
    font-size: calc(1rem + 1vw);
  }

.summary_data {
    width: 20%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
}

.summary_data_s1 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 28px;
    margin-bottom: 10px;
}

.summary_data_count {
    font-size: 48px;
    padding-right: 8px;
    vertical-align: bottom;

    display: flex;
    align-items: center;
    justify-content: center;
}

.summary_data_s2 {
    margin: 0;
    padding: 0;
    border: 0;
}

.post-preview > .post-meta {
    color: #808080;
    font-size: 18px;
    font-style: italic;
    margin-top: 0;
}
.post-preview > .post-meta a{
   color:#616161;	
}
.post-preview > .post-meta a:hover{
  color: #26a69a;	
}
  
.post-preview > a > .post-subtitle {
    margin: 0;
    color:#616161;
    font-weight: 300;
    margin-bottom: 10px;
}
.post-preview > a > .post-subtitle:hover{
  color: #26a69a;	
}
.post-title {
    font-size: 36px;
    color:#616161;
    margin-top: 30px;
    background-color: white;
}
.post-title:hover{
      color: #26a69a;
}
.post-subtitle {
    font-size: 24px;
    color:#616161;
    margin-bottom: 10px;
    background-color: white;
}
.card-content .post-title {
    white-space:nowrap;
}
.post-option {
    display: inline-block;
    content: "";
    width: 14px;
    height: 14px;
    border: 3px solid #CC0066 !important;
    border-radius: 50%;
}

.card-content {
    padding: 10%;
}

.summary_data_table {
    display: block;
    height: 200px;
    width: 100vw;
    background: hsla(0, 0%, 100%, 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    text-wrap: pretty;
}

.flex_box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    position: relative;
}
