.navbar-brand--img { height: 50px; }

.badge-notif { position: absolute; top: 1.2rem; margin-left: .7em; }

#bd-versions {
    /* border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-top: 14px; */
}

.rounded {
    width: 36px;
    height: 36px;
    border-radius: .25rem !important;
}

.cms-account-info {
    padding: 30px 20px;
    width: 270px;
    box-sizing: border-box;
    background: #fff;
    right: 0;
    z-index: 99;
    font-size: 12px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.cms-account-triangle-icon {
    font-style: normal;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom: 5px solid #fff;
    position: absolute;
    right: 15px;
    top: -9px;
    margin-left: -12px;
}

.cms-main-nav .cms-account-info-top {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
}

.cms-mine-avatar-wrap {
    float: left;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.cms-mine-avatar {
    width: 100%;
    height: 100%;
    vertical-align: top;
}

.cms-account-info-detail {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
    margin-left: 10px;
    color: #AAAAAA;
    line-height: 12px;
}

.cms-account-money-wrap {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
    text-align: center;
}

.cms-account-money-title {
    color: #AAAAAA;
    margin: 20px 0 15px;
    line-height: 12px;
}

.cms-account-money {
    font-family: "OPPOSansB";
    font-size: 20px;
    color: #FF405C;
    line-height: 24px;
    font-weight: normal;
}

.cms-account-money-icon {
    font-size: 16px;
}

.cms-account-handle-wrap {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
}

.cms-account-handle-btn {
    display: block;
    width: 170px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #AAAAAA;
    border-radius: 18px;
    border: 1px solid #cccccc;
    margin: 20px auto 0;
    box-sizing: border-box;
}